<template>
  <h1 class="text-h1 font-weight-light">
    <slot />
  </h1>
</template>

<script>
  export default {
    name: 'PagesHeading',
  }
</script>
